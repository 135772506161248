import Script from 'next/script';

/**
 * Polyfill 적용 사항
 * - Intersection Observer
 * - requestAnimationFrame
 */
const Polyfill = () => {
  return (
    <>
      <Script
        strategy="beforeInteractive"
        noModule
        src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.js?features=requestAnimationFrame%2CIntersectionObserver"
      />
    </>
  );
};

export default Polyfill;
