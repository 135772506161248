/** 미디어쿼리 Value */
export const mediaValue = {
  xlarge: 1200,
  large: 992,
  medium: 768,
  small: 576,
  xsmall: 375,
};

/** 미디어쿼리 프리셋 */
export const mediaQuery = {
  xlarge: `@media (min-width: ${mediaValue.xlarge}px)`,
  large: `@media (min-width: ${mediaValue.large}px)`,
  medium: `@media (min-width: ${mediaValue.medium}px)`,
  small: `@media (min-width: ${mediaValue.small}px)`,
  xsmall: `@media (min-width: ${mediaValue.xsmall}px)`,
  custom: value => `@media (min-width: ${value}px)`,
};

/** 색상표 */
export const palette = {
  white: '#ffffff',
  black: '#000000',

  /* Primary Color */
  red005: '#ffebef',
  red010: '#ffcdd3',
  red020: '#f9999a',
  red030: '#f37073',
  red040: '#ff4b4e',
  red050: '#ff3431',
  red060: '#f62932',
  red070: '#e41b2b',
  red080: '#d70e23',
  red090: '#c80017',

  /* Secondary Color */
  blue005: '#e3f3ff',
  blue070: '#2277ff',

  yellow005: '#fffde5',
  yellow060: '#ffd912',
  yellow080: '#fea600',

  /* Grey Color */
  grey005: '#f9f9f9',
  grey010: '#efefef',
  grey020: '#e9eaeb',
  grey030: '#dadbdc',
  grey040: '#b6b7b8',
  grey050: '#979899',
  grey060: '#6e6f70',
  grey070: '#5b5c5c',
  grey080: '#3a3a3a',
  grey090: '#1a1a1a',

  /* Service Color */
  service: {
    kakao: '#fee500',
    facebook: '#1877F2',
    naver: '#1ec800',
    apple: '#202020',
    phone: '#424242',
  },

  /* Background Color */
  background: {
    bg1: '#ffffff',
    bg2: '#f9f9f9',
  },

  /* Dim Color */
  dim: {
    thick: 'rgba(0,0,0,0.8)',
    basic: 'rgba(0,0,0,0.6)',
    hover: 'rgba(0,0,0,0.08)',
  },

  /* Border Color */
  border: {
    b1: '#efefef',
    b2: '#dadbdc',
  },

  /* Font Color */
  font: {
    primary: '#3a3a3a',
    secondary: '#1a1a1a',
    tertiary1: '#6e6f70',
    tertiary2: '#b6b7b8',
  },

  /* Semantic Color */
  semantic: {
    emphasis: '#2277ff',
    warning: '#f62932',
    caution: '#efb008',
  },

  // cds v2
  primary_400: '#FF6A49',
  primary_500: '#FF5935',

  secondary_300: '#B786FE',
  secondary_500: '#883EFE',

  orange_500: '#FF5935',

  yellow_500: ' #FFBF00',

  grey_50: '#F5F5F5',
  grey_100: '#F0F0F0',
  grey_400: '#BABABA',
  grey_500: '#A3A3A3',
  grey_600: '#7a7a7a',
  grey_800: '#424242',
  grey_900: '#1a1a1a',

  // DARK MODE - grey scale
  grey0: '#7f7f7f',
  grey25: '#a2a2a2',
  grey50: '#cacaca',
  grey75: '#eeeeee',
  grey100: '#ffffff',

  // DARK MODE - System
  system: {
    BLACK: '#0B0B0C',
    Null: '#111112',
    BG: '#151516',
    BNB: '#212226',
    grey: '#444445',
  },

  // DARK MODE - Btn
  BTN: {
    BG: '#2c2d31',
    Color0: '#acb1b6',
    color1: '#8d9093',
  },

  // DARK MODE - Primary
  Primary: {
    Active: '#f21840',
    Active_Down_Tone: '#da4762',
    deactive: '#711f31',
  },

  // DARK MODE - Secondary
  Secondary: {
    Mint: '#26b1c4',
    Orange: '#f18880e',
    Yellow: '#fec909',
    purple: '#ac78ff',
  },
};

/** z-index 프리셋 */
export const zIndex = {
  header: 75,
  footer: 50,
  aside: 40,

  notification: 75,

  modal: 100,
  toast: 200,
  loading: 500,
};

/**
 * Enable only hover
 * @example
 * ${onlyHover} {
 *   &:hover { ... }
 * }
 * &:active { ... }
 */
export const onlyHover = `@media (hover: hover) and (pointer: fine), only screen and (-ms-high-contrast: active), (-ms-high-contrast: none)`;
