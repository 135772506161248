import { createAsyncThunk } from '@reduxjs/toolkit';
// actions
import * as actions from 'slices/user';
// api
import * as userAPI from 'api/user';
import * as recommendAPI from 'api/recommend';
import * as notificationAPI from 'api/notification';
import * as feedAPI from 'api/feed';
// utils
import * as commonUtils from 'lib/utils/common';

/**
 * @dispatch 사용자 데이터 호출 (Light Version)
 */
export const getUserData = createAsyncThunk('user/getUserData', async (_, { dispatch }) => {
  try {
    const res = await userAPI.getUser();

    /* 사용자 정보 */
    dispatch(
      actions.updateInfo({
        id: res.user_id,
        name: res.user_info.user_name,
        photo: res.user_info.user_photo,
        email: res.user_info.email,
        phone: res.user_info.phone,
        isCoach: res.coach_yn,
        isShowRequirementModal: res.class_list.length > 0 && !res.user_info.email,
      }),
    );

    /* 사용자 클래스 정보 */
    dispatch(
      actions.updateClassList({
        registered: res.class_list,
      }),
    );

    return Promise.resolve(true);
  } catch (error) {
    const statusCode = error.code;

    if (statusCode === 470) {
      return 470;
    }

    console.error(error);
  }
});

/**
 * @dispatch 제휴 회원 가입
 * @param {object} props
 */
export const postRegisterContactMember = createAsyncThunk(
  'user/postRegisterContactMember',
  async ({ data }) => {
    return userAPI.postRegisterContactMember(data);
  },
);

/**
 * @dispatch 비즈 회원 가입
 * @param {object} props
 */
export const postRegisterBizMember = createAsyncThunk(
  'user/postRegisterBizMember',
  async ({ data }) => {
    return userAPI.postRegisterBizMember(data);
  },
);

/**
 * @dispatch 제휴 비즈 회원 가입
 * @param {object} props
 */
export const postRegisterContactBizMember = createAsyncThunk(
  'user/postRegisterContactBizMember',
  async ({ data }) => {
    return userAPI.postDiscountBizUser(data);
  },
);

/**
 * @dispatch 비즈 회원 비밀번호 찾기 메일 발송
 * @param {object} props
 * @param {object} props.emailList 연락처(유저 아이디)
 */
export const postFindMyPassword = createAsyncThunk('user/postFindMyPassword', async ({ data }) => {
  return userAPI
    .postFindMyPassword(data)
    .then(() => {})
    .catch(() => {});
});

/**
 * @dispatch 사용자 데이터 호출 (Heavy Version)
 */
export const getUserDataAll = createAsyncThunk('user/getUserData', async (_, { dispatch }) => {
  const res = await userAPI.getUserAll();

  /* 사용자 정보 */
  dispatch(
    actions.updateInfo({
      id: res.user_id,
      name: res.user_info.user_name,
      photo: res.user_info.user_photo,
      email: res.user_info.email,
      isCoach: res.coach_yn,
    }),
  );

  /* 사용자 클래스 정보 */
  dispatch(
    actions.updateClassList({
      registered: res.class_list,
      management: res.management_class,
    }),
  );

  return Promise.resolve(true);
});

/**
 * @dispatch 사용자 데이터 수정
 * @param {object} props
 * @param {string?} props.nickname
 * @param {string?} props.introduce
 * @param {string?} props.email
 * @param {string?} props.instagram
 * @param {string?} props.facebook
 * @param {string?} props.youtube
 * @param {string?} props.homepage
 *
 * @param {number?} props.userId
 * @param {number?} props.phoneNumber
 * @param {string?} props.kakaoId
 *
 * @param {url_1} props.photo_url
 */
export const updateUserData = createAsyncThunk(
  'user/updateUserData',
  async (
    {
      nickname,
      introduce = '',
      email = '',
      instagram = '',
      facebook = '',
      youtube = '',
      homepage = '',

      // '' 이면 변경 X
      userId,
      phoneNumber = '',
      kakaoId = '',

      // undefined 면 변경 X
      url_1,

      myPhoto,
    },
    { dispatch },
  ) => {
    const pendingList = [];

    if (nickname) {
      const data = {
        nickname,
        profile_comment: introduce,
        email,
        sns_instagram: instagram,
        sns_facebook: facebook,
        sns_youtube: youtube,
        sns_homepage: homepage,
      };

      if (url_1) {
        Object.assign(data, { url_1 });
      }

      const community_data = {
        user_id: userId,
        nickname,
        photo: myPhoto,
        profile_comment: introduce,
      };

      const formData = commonUtils.createFormdata(data);

      const job = userAPI.postChangeProfile(formData);
      pendingList.push(job);

      const communityJob = userAPI.postChangeCommunityProfile(community_data);
      pendingList.push(communityJob);
    }

    if (userId && (phoneNumber || kakaoId)) {
      const job = userAPI.postChangeProfileNumber({
        userId,
        phoneNumber,
        kakaoId,
        isAbroad: kakaoId ? 'Y' : 'N',
      });
      pendingList.push(job);
    }

    const res = await Promise.all(pendingList);

    dispatch(getEditProfile());

    return res;
  },
);

/**
 * @dispatch 사용자 이메일 수정
 * @param {object} props
 * @param {string?} props.email
 */
export const updateUserEmail = createAsyncThunk(
  'user/updateUserEmail',
  async ({ email }, { dispatch }) => {
    if (!email) {
      return;
    }

    const data = commonUtils.createFormdata({ email });
    const res = await userAPI.postChangeProfile(data);

    dispatch(getUserData());

    return res;
  },
);

/**
 * @dispatch 사용자 포인트 리스트 호출
 * @param {object} props
 * @param {number} props.page 페이징
 */
export const getUserPointList = createAsyncThunk(
  'user/getUserPointList',
  async ({ page }, { dispatch }) => {
    const res = await userAPI.getUserPointList({ page }).catch(() => {
      return Promise.reject();
    });

    dispatch(actions.setPointList(res));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 사용자 추천 클래스 목록 호출
 * - 쿠폰 리워드 뷰
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getUserRecommendClassList = createAsyncThunk(
  'user/UserRecommendClassList',
  async ({ userId }, { dispatch }) => {
    const res = await recommendAPI.getUserRecommendClassList({ userId });

    /* 사용자 정보 */
    dispatch(actions.setUserRecommendClassList(res.vogue_class.class_list));
  },
);

/**
 * @dispatch 확인하지 않은 챗, 알림 카운트 정보 호출
 * @param {object} props
 */
export const getNewsCount = createAsyncThunk('user/getNewsCount', async (_, { dispatch }) => {
  return userAPI
    .getNewsCount()
    .then(res => {
      dispatch(actions.setNewsCount(res));
    })
    .catch(() => false);
});

/**
 * @dispatch 알림 호출
 * @param {object} props
 * @param {number} props.page 페이징
 */
export const getNotificationList = createAsyncThunk(
  'user/getNotificationList',
  async ({ page }, { dispatch }) => {
    return notificationAPI
      .getNotificationList({ page })
      .then(res => {
        const filtered = res.list.filter(v => v.push_type >= 0 || v.push_type);
        if (page === 1) {
          dispatch(actions.setNotificationList(filtered));
        } else {
          dispatch(actions.pushNotificationList(filtered));
        }
        dispatch(
          actions.setNotificationInfo({
            nextPage: res.page + 1,
            total_page: res.total_page,
          }),
        );

        return filtered;
      })
      .catch(() => false);
  },
);

/** @dispatch 알림 전체 읽기 */
export const readAllNotification = createAsyncThunk(
  'user/readAllNotification',
  async (_, { getState, dispatch }) => {
    const notificatonList = getState().user.notificationList;
    const mapped = [...notificatonList].map(noti => {
      return {
        ...noti,
        read_status: 'Y',
      };
    });

    dispatch(actions.setNotificationList(mapped));
    dispatch(actions.setNotificationInfo(0));

    const unreadCount = getState().user.newsCount;
    const update = Object.assign({}, unreadCount, {
      news_unread_count: 0,
    });

    dispatch(actions.setNewsCount(update));

    return notificationAPI
      .readAllNotification()
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 알림 읽기
 * @param {object} props
 * @param {number} props.notifyId 알림 ID
 * @param {number} props.readStatus 확인 여부
 */
export const readNotification = createAsyncThunk(
  'user/readNotification',
  async ({ notifyId, readStatus }, { getState, dispatch }) => {
    const notificatonList = getState().user.notificationList;

    const mapped = [...notificatonList].map(noti => {
      if (noti.id === notifyId) {
        return {
          ...noti,
          read_status: 'Y',
        };
      }
      return noti;
    });

    dispatch(actions.setNotificationList(mapped));

    // 미확인 알림을 확인할 경우, 알림 카운트를 감소시킵니다.
    if (readStatus === 'N') {
      const unreadCount = getState().user.newsCount;
      const update = Object.assign({}, unreadCount, {
        news_unread_count: unreadCount.news_unread_count - 1,
      });

      dispatch(actions.setNewsCount(update));
    }

    return notificationAPI
      .readNotification({ notifyId })
      .then(res => res)
      .catch(() => false);
  },
);

/** @dispatch 알림 전체 제거 */
export const removeAllNotification = createAsyncThunk(
  'user/removeAllNotification',
  async (_, { getState, dispatch }) => {
    const unreadCount = getState().user.newsCount;
    const update = Object.assign({}, unreadCount, {
      news_unread_count: 0,
    });

    dispatch(actions.setNewsCount(update));
    dispatch(actions.setNotificationList([]));
    dispatch(actions.setNotificationInfo(0));

    return notificationAPI
      .removeAllNotification()
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 알림 제거
 * @param {object} props
 * @param {number} props.notifyId 알림 ID
 * @param {number} props.readStatus 확인 여부
 */
export const removeNotification = createAsyncThunk(
  'user/removeNotification',
  async ({ notifyId, readStatus }, { getState, dispatch }) => {
    const notificatonList = getState().user.notificationList;
    const filtered = notificatonList.filter(noti => noti.id !== notifyId);
    dispatch(actions.setNotificationList(filtered));

    // 미확인 알림을 확인할 경우, 알림 카운트를 감소시킵니다.
    if (readStatus === 'N') {
      const unreadCount = getState().user.newsCount;
      const update = Object.assign({}, unreadCount, {
        news_unread_count: unreadCount.news_unread_count - 1,
      });

      dispatch(actions.setNewsCount(update));
    }

    return notificationAPI
      .removeNotification({ notifyId })
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 휴대폰 로그인 사용자 계정 확인
 * @param {object} props
 * @param {number | string} data.user_id 연락처(유저 아이디)
 * @param {number | string} data.passwd  비밀번호
 */
export const postUserAuth = createAsyncThunk('user/postUserAuth', async ({ user_id, passwd }) => {
  return userAPI
    .postUserAuth({ user_id, passwd })
    .then(() => {
      return { message: 'success' };
    })
    .catch(error => {
      return { message: '휴대폰 번호 또는 비밀번호를 확인해주세요' };
    });
});

/**
 * @dispatch 사용자 회원가입 인증번호 받기
 * @param {object} props
 * @param {number} props.phone 휴대폰 번호
 */
export const postSendAuth = createAsyncThunk('user/postSendAuth', async ({ phone }) => {
  return userAPI
    .postSendAuth({ phone })
    .then(res => {
      if (res.code === '200') {
        return { message: 'success' };
      } else if (res.code === '422') {
        return { message: '올바른 휴대폰 번호를 입력해 주세요' };
      } else if (res.code === '501') {
        return {
          message:
            '과거 인증받은 기록이 있습니다. \n 휴대폰 번호로 로그인 후 신청해 주세요. 비밀번호가 기억이 안 나시면 재설정 후 로그인해 주세요',
        };
      } else {
        return { message: '데이터 요청을 실패했습니다. 고객센터에 문의하세요' };
      }
    })
    .catch(() => {
      return { message: '서버 장애로 데이터 요청을 실패했습니다. 고객센터에 문의하세요' };
    });
});

/**
 * @dispatch 사용자 회원가입 인증번호 확인
 * @param {object} props
 * @param {number} props.hpno 연락처(유저 아이디)
 * @param {number} props.authno 인증번호
 */
export const postCheckSmsAuth = createAsyncThunk(
  'user/postCheckSmsAuth',
  async ({ hpno, authno }) => {
    return userAPI
      .postCheckSmsAuth({ hpno, authno })
      .then(res => {
        if (res.code === '200') {
          return { message: 'success' };
        } else if (res.code === '421') {
          return { message: '유효하지 않은 인증번호 입니다' };
        } else if (res.code === '422') {
          return { message: '올바른 휴대폰 번호를 입력해 주세요' };
        } else if (res.code === '472') {
          return { message: '인증번호 입력 시간이 초과되었습니다. 인증번호를 다시 요청해 주세요' };
        } else if (res.code === '501') {
          return {
            message:
              '과거 인증받은 기록이 있습니다. \n 휴대폰 번호로 로그인 후 신청해 주세요. 비밀번호가 기억이 안 나시면 재설정 후 로그인해 주세요',
          };
        } else if (res.code === '502') {
          return { message: '인증번호를 요청해 주세요' };
        } else {
          return { message: '데이터 요청을 실패했습니다. 고객센터에 문의하세요' };
        }
      })
      .catch(res => {
        if (res.code === '421') {
          return { message: '유효하지 않은 인증번호 입니다' };
        } else if (res.code === '472') {
          return { message: '인증번호 입력 시간이 초과되었습니다. 인증번호를 다시 요청해 주세요' };
        } else {
          return { message: '서버 장애로 데이터 요청을 실패했습니다. 고객센터에 문의하세요' };
        }
      });
  },
);

/**
 * @dispatch 최근 본 클래스 호출
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getRecentClassList = createAsyncThunk(
  'user/getRecentClassList',
  async ({ userId = '' }, { dispatch }) => {
    const res = await userAPI.getRecentClassList({ userId });

    dispatch(actions.setRecentRecommendClassList(res));

    return res;
  },
);

/**
 * @dispatch 관심 클래스 호출
 * @param {object} props
 * @param {string} props.page 페이징
 * @param {number} props.userId 유저 ID
 */
export const getBookmarkClassList = createAsyncThunk(
  'user/getBookmarkClassList',
  async ({ skip, limit, userId = '' }, { dispatch }) => {
    const res = await userAPI.getBookmarkClassList({ skip, limit, userId });

    if (skip === 0) {
      dispatch(actions.setBookmarkClassList(res));
    } else {
      dispatch(actions.pushBookmarkClassList(res));
    }

    return res;
  },
);

/**
 * @dispatch 관심 추가/삭제
 * @param {object} props
 * @param {'add' | 'delete'} props.type
 * @param {number} props.userId 유저 ID
 */
export const postBookmark = createAsyncThunk(
  'user/postBookmark',
  async ({ classId, type = 'add' }, { getState, dispatch }) => {
    const sednData = {
      mode: type,
    };

    await userAPI
      .postBookmark({ classId, data: commonUtils.createFormdata(sednData) })
      .catch(() => {
        return Promise.reject();
      });

    const prevList = getState().user.bookmarkClassList;

    const isHave = type === 'add' ? 'Y' : 'N';
    const newList = prevList.map(v =>
      classId === v.class_id ? { ...v, class_have_status: isHave } : v,
    );

    dispatch(actions.setBookmarkClassList(newList));

    return Promise.resolve(true);
  },
);

/**
 * @dispatch 프로필 정보 가져오기
 * @param {object} props
 * @param {number} props.userId 유저 ID
 * @param {number} props.page
 */
export const getProfile = createAsyncThunk(
  'user/getProfile',
  async ({ userId, page = 1 }, { dispatch }) => {
    const res = await userAPI.getProfile({ userId, page });

    if (page === 1) {
      dispatch(actions.setProfile(res));
    } else {
      dispatch(actions.pushProfileActivity(res.comment_list));
    }

    return res;
  },
);

/**
 * @dispatch 수정 페이지 프로필 정보 가져오기
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const getEditProfile = createAsyncThunk('user/getEditProfile', async (_, { dispatch }) => {
  const res = await userAPI.getEditProfile();

  dispatch(actions.setEditProfile(res));
  return res;
});

/**
 * @dispatch 사용자 부가 정보 가져오기 (레벨링, 적립금, 팔로잉)
 */
export const getUserSubInfo = createAsyncThunk('user/getUserSubInfo', async (_, { dispatch }) => {
  const res = await userAPI.getUserSubInfo().catch(() => {
    return Promise.reject();
  });

  dispatch(actions.setSubInfo(res));

  return Promise.resolve(true);
});

/**
 * @dispatch 사용자 팔로우
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const postFollow = createAsyncThunk(
  'user/postFollow',
  async ({ userId }, { getState, dispatch }) => {
    const profile = getState().user.profile;
    dispatch(actions.setProfile({ ...profile, friend_yn: 'Y' }));

    return feedAPI
      .postFollow({ userId })
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 사용자 팔로우 취소
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const postUnfollow = createAsyncThunk(
  'user/postUnfollow',
  async ({ userId }, { getState, dispatch }) => {
    const profile = getState().user.profile;
    dispatch(actions.setProfile({ ...profile, friend_yn: 'N' }));

    return feedAPI
      .postUnfollow({ userId })
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 사용자 차단
 * @param {object} props
 * @param {number} props.userId 유저 ID
 */
export const postBlockUser = createAsyncThunk(
  'user/postBlockUser',
  async ({ userId }, { getState, dispatch }) => {
    const profile = getState().user.profile;
    dispatch(actions.setProfile({ ...profile, block_flag: 'Y' }));

    return userAPI
      .postBlockUser({ userId })
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 사용자 차단 해제
 * @param {object} props
 * @param {number} props.blockUserId 차단 해제할 유저 ID
 */
export const postCancelBlockedUser = createAsyncThunk(
  'user/postCancelBlockedUser',
  async ({ blockUserId }, { getState, dispatch }) => {
    const profile = getState().user.profile;
    dispatch(actions.setProfile({ ...profile, block_flag: 'N' }));

    return userAPI
      .postCancelBlockedUser({ blockUserId })
      .then(res => res)
      .catch(() => false);
  },
);

/**
 * @dispatch 사용자 팔로우 호출
 * @param {object} props
 * @param {number} props.userId
 * @param {number} props.page 현재 페이지
 * @param {number} props.limit 한 번에 가져올 개수
 */
export const getFollow = createAsyncThunk(
  'user/getFollow',
  async ({ userId, page = 1, limit = 20 }, { dispatch }) => {
    const res = await userAPI
      .getFollow({
        userId,
        page,
        limit,
      })
      .catch(() => {
        return Promise.reject();
      });

    if (page === 1) {
      dispatch(actions.setFollow(res.list));
    } else {
      dispatch(actions.pushFollow(res.list));
    }

    return Promise.resolve(res);
  },
);

/**
 * @dispatch 사용자 팔로워 호출
 * @param {object} props
 * @param {number} props.userId
 * @param {number} props.page 현재 페이지
 * @param {number} props.limit 한 번에 가져올 개수
 */
export const getFollower = createAsyncThunk(
  'user/getFollower',
  async ({ userId, page = 1, limit = 20 }, { dispatch }) => {
    const res = await userAPI
      .getFollower({
        userId,
        page,
        limit,
      })
      .catch(() => {
        return Promise.reject();
      });

    if (page === 1) {
      dispatch(actions.setFollower(res.list));
    } else {
      dispatch(actions.pushFollower(res.list));
    }

    return Promise.resolve(res);
  },
);

/**
 * @dispatch 사용자 차단 리스트 호출
 * @param {object} props
 * @param {number} props.page 현재 페이지
 * @param {number} props.limit 한 번에 가져올 개수
 */
export const getBlockedUser = createAsyncThunk(
  'user/getBlockedUser',
  async ({ page = 1, limit = 20 }, { dispatch }) => {
    const res = await userAPI
      .getBlockedUser({
        page,
        limit,
      })
      .catch(() => {
        return Promise.reject();
      });

    if (page === 1) {
      dispatch(actions.setBlockedUser(res.list));
    } else {
      dispatch(actions.pushBlockedUser(res.list));
    }

    return Promise.resolve(res);
  },
);

/**
 * @dispatch 사용자 차단 리스트 호출
 * @param {object} props
 * @param {number} props.page 현재 페이지
 * @param {number} props.limit 한 번에 가져올 개수
 */
export const postSecession = createAsyncThunk('user/postSecession', async () => {
  const res = await userAPI.postSecession().catch(() => {
    return Promise.reject();
  });

  return Promise.resolve(res);
});

/**
 * @dispatch 의무 교육 수강 여부 확인 호출
 * @param {object} props
 * @param {userId} props.userId 유저 아이디
 */
export const getCompulsory = createAsyncThunk(
  'user/getCompulsory',
  async ({ userId }, { dispatch }) => {
    const res = await userAPI.getCompulsoryCheck({ userId }).catch(() => {
      return Promise.reject();
    });

    dispatch(actions.setCompulsory(res.data));

    return Promise.resolve(res);
  },
);

/**
 * @dispatch 유저 회사 정보 조회 호출
 * @param {object} props
 * @param {userId} props.userId 유저 아이디
 */

export const getUserCompany = createAsyncThunk(
  'user/getUserCompany',
  async ({ userId }, { dispatch }) => {
    const res = await userAPI.getBizCompany({ userId }).catch(() => {
      return Promise.reject();
    });

    dispatch(actions.setUserCompany(res.company));

    return Promise.resolve(res);
  },
);
