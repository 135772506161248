/* eslint-disable react-hooks/rules-of-hooks */
import Head from 'next/head';
// Head
import SiteVerification from 'head/SiteVerification';
import Advertisement from 'head/advertisement';
import { GoogleTagManager } from 'head/advertisement/google';
import KakaoAdvertisement from 'head/advertisement/kakao';
import TiktokAdvertisement from 'head/advertisement/tiktok';
import NaverAnalytics from 'head/analytics/Naver';
import KakaoSDK from 'head/sdk/KakaoSDK';
// polyfill
import Polyfill from 'head/Polyfill';
import SmoothBehaviorPolyfill from 'head/SmoothBehaviorPolyfill';
// Store
import storeWrapper from 'slices';
// hooks
import useMetaData from 'hooks/common/useMetaData';
import useAuthorization from 'hooks/domain/main/useAuthorization';
import useCheckLogin from 'hooks/domain/main/useCheckLogin';
import useNotification from 'hooks/domain/main/useNotification';
import useToast from 'hooks/common/useToast';
import useModal from 'hooks/common/useModal';
import useWebview from 'hooks/common/useWebview';
// icons
import SpriteIcons from 'lib/icons/SpriteIcons';
// styles
import 'public/fonts.css';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useAttachPageEvent, useSetURLData } from 'hooks/dataMining/useDataMining';
import deviceChecker from 'lib/utils/deviceChecker';
import { isInBrowser } from 'lib/utils/condition';
import TwitterAdvertisement from 'head/advertisement/twitter';

const ogImage = 'https://file.classu.co.kr/image/1847901/2024-04-22/15:51:19/classu_og_image.png';

const App = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  useAuthorization();
  useCheckLogin();
  useWebview();
  useNotification();

  const { MetaTitle, MetaImage } = useMetaData();
  const { Toast } = useToast();
  const { Modal } = useModal();
  const isProd = process.env.NODE_ENV === 'production';

  const getLayout = Component.getLayout || (page => page);
  const router = useRouter();

  if (isInBrowser() && !deviceChecker().app()) {
    useSetURLData(router, dispatch);
    useAttachPageEvent(router, dispatch);
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes, minimal-ui, viewport-fit=cover"
        />
        {isProd && <SiteVerification />}
      </Head>
      <MetaTitle content="세상 모든 배움 초특가!!!" />
      <MetaImage content={ogImage} />
      {/* -------------------------------- Sellper Verification ------------------------------- */}
      {/* ----------------------------------- Selper Setting ---------------------------------- */}
      {isProd && <Advertisement />}
      {isProd && <GoogleTagManager />}
      {isProd && <KakaoAdvertisement />}
      {isProd && <TiktokAdvertisement />}
      {isProd && <TwitterAdvertisement />}
      {/* ----------------------------------------- SDK --------------------------------------- */}
      <KakaoSDK />
      {/* -------------------------------------- Polyfill ------------------------------------- */}
      <Polyfill />
      <SmoothBehaviorPolyfill />
      {/* ---------------------------------------- Main --------------------------------------- */}
      {getLayout(<Component {...pageProps} />)}
      <div id="cds-bottomsheet" />
      <Modal />
      <Toast />
      <SpriteIcons />
      {/* ----------------------------------- Naver Analytics --------------------------------- */}
      {isProd && <NaverAnalytics />}
    </>
  );
};

export default storeWrapper.withRedux(App);
