import { useState } from 'react';
import styled from 'styled-components';
import { useEffect, useCallback, useRef } from 'react';

/**
 * 가로 스크롤 컴포넌트
 */
const HorizontalSlider = ({ children }) => {
  const [rudder, setRudder] = useState({ prev: false, next: false });
  const slider = useRef(null);
  const direction = useRef('START');
  const touchX = useRef(0);
  const prevTouchX = useRef(0);
  const standardX = useRef(0);

  // 슬라이더 블라인더 초기화
  const initBlinder = useCallback(() => {
    const scrollWidth = slider.current.scrollWidth;
    const offsetWidth = slider.current.offsetWidth;

    if (scrollWidth <= offsetWidth) {
      return;
    }

    setRudder(prev => ({
      ...prev,
      next: true,
    }));
  }, [slider]);

  // 스크롤에 따른 슬라이더 블라인더 보여주기
  const visibleNavigation = useCallback(() => {
    if (direction.current === 'START' && rudder.prev) {
      setRudder(prev => ({
        ...prev,
        prev: false,
      }));
    }

    if (direction.current === 'MIDDLE' && (!rudder.prev || !rudder.next)) {
      setRudder(() => ({
        next: true,
        prev: true,
      }));
    }

    if (direction.current === 'END' && rudder.next) {
      setRudder(prev => ({
        ...prev,
        next: false,
      }));
    }
  }, [direction, rudder.next, rudder.prev]);

  const onScroll = useCallback(() => {
    const scrollWidth = slider.current.scrollWidth;
    const offsetWidth = slider.current.offsetWidth;
    const scrollLeft = slider.current.scrollLeft;

    if (scrollLeft === 0) {
      direction.current = 'START';
    } else {
      direction.current = 'MIDDLE';

      if (scrollLeft === scrollWidth - offsetWidth) {
        direction.current = 'END';
      }
    }

    visibleNavigation();
  }, [visibleNavigation]);

  const unify = e => (e.changedTouches ? e.changedTouches[0] : e);

  const onMouseMove = useCallback(e => {
    touchX.current = Math.round((prevTouchX.current - unify(e).clientX) * 1.5);
    slider.current.scrollLeft = standardX.current + touchX.current;
  }, []);

  const onMouseUp = useCallback(() => {
    standardX.current = slider.current.scrollLeft;
    slider.current.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }, [onMouseMove]);

  const onMouseDown = useCallback(
    e => {
      e.preventDefault();
      prevTouchX.current = unify(e).clientX;
      standardX.current = slider.current.scrollLeft;
      slider.current.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [onMouseMove, onMouseUp],
  );

  useEffect(() => {
    const target = slider.current;
    if (!target) {
      return;
    }

    target.addEventListener('mousedown', onMouseDown);
    target.addEventListener('scroll', onScroll);
    return () => {
      target.removeEventListener('mousedown', onMouseDown);
      target.removeEventListener('scroll', onScroll);
    };
  }, [initBlinder, onMouseDown, onScroll]);

  useEffect(() => {
    initBlinder();
  }, [slider, children, initBlinder]);

  return (
    <Wrapper rudder={rudder}>
      <ContentWrapper ref={slider}>{children}</ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-grow: 1;
  position: relative;
  width: auto;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 16px;
    height: 100%;
    z-index: 1;
  }

  &::before {
    display: ${({ rudder }) => (rudder.prev ? 'block' : 'none')};
    /* background: linear-gradient(to left, rgba(255, 255, 255, 0), white); */
    left: 0;
  }

  &::after {
    display: ${({ rudder }) => (rudder.next ? 'block' : 'none')};
    /* background: linear-gradient(to left, white, rgba(255, 255, 255, 0)); */
    right: 0;
  }
`;

const ContentWrapper = styled.ul`
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;

  & > li {
    & ~ * {
      margin-left: 16px;
    }
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default HorizontalSlider;
