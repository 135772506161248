import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
// components
import IconButton from 'cds/buttons/Icon';
import Icon from 'cds/icons';
// styles
import { mediaQuery, palette } from 'cds/styles';

/**
 * 스크롤 슬라이더 Navigation
 * @param {object} props
 * @param {object} props.ref 슬라이더 Wrapper Ref
 * @param {NodeElement} props.ref.current
 * @param {object[]} props.list
 * @example
 * const [rudder, onPrev, onNext] = useNavigationOnSlider({
 *    ref: sliderRef,
 *    list: classList,
 * });
 * @returns {[
 *    Navigation: React.Element
 * ]}
 */

const useNavigationOnSlider = ({ ref, list = [] }) => {
  const [rudder, setRudder] = useState({ prev: false, next: false });
  const [top, setTop] = useState(0);
  const direction = useRef('START');

  // 슬라이더 크기 가져오기
  const getSliderWidth = useCallback(() => {
    const slider = ref.current;
    const scrollWidth = slider.scrollWidth;
    const offsetWidth = slider.offsetWidth;
    const offsetHeight = slider.offsetHeight;

    return {
      scrollWidth,
      offsetWidth,
      offsetHeight,
    };
  }, [ref]);

  // 슬라이더 포지션 가져오기
  const getScrollPosition = useCallback(() => {
    const slider = ref.current;
    const scrollLeft = slider.scrollLeft;

    return {
      scrollLeft,
    };
  }, [ref]);

  // 슬라이더 네비게이션 초기화
  const initNavigator = useCallback(() => {
    const boundary = getSliderWidth();

    if (boundary.scrollWidth <= boundary.offsetWidth) {
      return;
    }

    setRudder(prev => ({
      ...prev,
      next: true,
    }));
  }, [getSliderWidth]);

  // 스크롤에 따른 슬라이더 네비게이션 보여주기
  const visibleNavigation = useCallback(() => {
    if (direction.current === 'START' && rudder.prev) {
      setRudder(prev => ({
        ...prev,
        prev: false,
      }));
    }

    if (direction.current === 'MIDDLE' && (!rudder.prev || !rudder.next)) {
      setRudder(() => ({
        next: true,
        prev: true,
      }));
    }

    if (direction.current === 'END' && rudder.next) {
      setRudder(prev => ({
        ...prev,
        next: false,
      }));
    }
  }, [direction, rudder.next, rudder.prev]);

  const onScroll = useCallback(() => {
    const boundary = getSliderWidth();
    const position = getScrollPosition();

    if (position.scrollLeft === 0) {
      direction.current = 'START';
    } else {
      direction.current = 'MIDDLE';

      if (position.scrollLeft === boundary.scrollWidth - boundary.offsetWidth) {
        direction.current = 'END';
      }
    }

    visibleNavigation();
  }, [getScrollPosition, getSliderWidth, visibleNavigation]);

  const onPrev = () => {
    const boundary = getSliderWidth();
    const position = getScrollPosition();

    const value =
      position.scrollLeft -
      boundary.offsetWidth +
      boundary.scrollWidth / list.length -
      (position.scrollLeft % (boundary.scrollWidth / list.length));

    ref.current.scrollTo({
      left: value,
      behavior: 'smooth',
    });
  };

  const onNext = () => {
    const boundary = getSliderWidth();
    const position = getScrollPosition();

    const value =
      position.scrollLeft +
      boundary.offsetWidth -
      (position.scrollLeft % (boundary.scrollWidth / list.length));

    ref.current.scrollTo({
      left: value,
      behavior: 'smooth',
    });
  };

  /** next button display 여부 */
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    initNavigator();
  }, [ref, list, initNavigator]);

  // 슬라이더 스크롤 이벤트 연결
  useEffect(() => {
    const slider = ref.current;
    if (!slider) {
      return;
    }

    slider.addEventListener('scroll', onScroll);
    return () => slider.removeEventListener('scroll', onScroll);
  }, [ref, onScroll]);

  useEffect(() => {
    if (!list.length) {
      return;
    }

    const boundary = getSliderWidth();
    const height = ((boundary.scrollWidth / list.length - 16) * 0.6097) / 2;
    const percent = (height / boundary.offsetHeight) * 100;

    setTop(percent);
  }, [getSliderWidth, list]);

  const PrevButton = () => (
    <ControlButton type="button" onClick={onPrev} width={54} height={54} rudder={rudder} top={top}>
      <Icon name="ic_arrow_left_r" width={32} height={32} fill={palette.grey50} />
    </ControlButton>
  );

  const NextButton = () => (
    <ControlButton type="button" onClick={onNext} width={54} height={54} rudder={rudder} top={top}>
      <Icon name="ic_arrow_right_r" width={32} height={32} fill={palette.grey50} />
    </ControlButton>
  );

  const Navigation = () => (
    <>
      <PrevButton />
      <NextButton />
    </>
  );

  return [Navigation];
};

const ControlButton = styled(IconButton)`
  display: none;
  opacity: 1;
  position: absolute;
  ${({ top }) => (top ? `top: calc(${top}%);` : '')}
  margin-top: -27px;
  border-radius: 100%;
  background-color: ${palette.system.BLACK};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  font-size: 0;
  border: 1px solid ${palette.BTN.BG};

  &:first-of-type {
    left: -27px;

    ${mediaQuery.large} {
      display: ${({ rudder, top }) => (rudder.prev && top ? 'block' : 'none')};
    }
  }

  &:last-of-type {
    right: -27px;

    ${mediaQuery.large} {
      display: ${({ rudder, top }) => (rudder.next && top ? 'block' : 'none')};
    }
  }

  &:hover {
    background-color: ${palette.system.grey};
    border: 1px solid ${palette.grey0};
    & > svg {
      fill: ${palette.grey75};
    }
  }
`;

export default useNavigationOnSlider;
