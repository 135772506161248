import styled from 'styled-components';
import Link from 'next/link';
// components
import TextButton from 'cds/buttons/Text';
import Icon from 'cds/icons';
// styles
import { mediaQuery, palette } from 'cds/styles';
import * as typography from 'cds/typography';

/**
 * 카테고리 선택 리스트
 * @param {object} props
 * @param {string[]} props.categoryList
 * @param {Function} props.onClose
 */
const CategoryList = ({ categoryList = [], onClose }) => {
  const CategoryButtonList = categoryList.map(category => (
    <Link key={category.category} href={`/category?categoryId=${category.category}`} passHref>
      <Button as="a" onClick={onClose}>
        <div>
          <RightIcon url={category.icon} />
          {category.category}
        </div>
        <Icon name="ic_arrow_right_s" width={24} height={24} fill={palette.grey060} />
      </Button>
    </Link>
  ));

  return (
    <TitleSectionWrapper>
      <TitleSection>
        <h1>카테고리 찾아보기</h1>
      </TitleSection>
      {CategoryButtonList}
    </TitleSectionWrapper>
  );
};
const TitleSectionWrapper = styled.section`
  background-color: ${palette.system.BG};
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 16px;
  background-color: ${palette.system.BG};

  & > h1 {
    ${typography.subtitle3_medium};
    color: ${palette.grey50};
  }
`;

const Button = styled(TextButton)`
  ${typography.body3_regular};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: ${palette.system.BG};

  svg {
    fill: ${palette.grey50};
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey50};

    & > svg {
      margin-right: 8px;
      filter: brightness(2) invert(0.6) grayscale(100%);
    }
  }

  & ~ & {
    border-top: 1px solid ${palette.system.BNB};
    border-radius: 0;

    ${mediaQuery.large} {
      border-color: ${palette.system.grey};
    }
  }
`;

const RightIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-image: ${({ url }) => url && `url(${url})`};
  background-position: center;
  background-size: contain;
`;

export default CategoryList;
