import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
// components
import TextButton from 'cds/buttons/Text';
import Icon from 'cds/icons';
// hooks
import useModal from 'hooks/common/useModal';
// styles
import * as typography from 'cds/typography';
import { palette, zIndex, mediaQuery, onlyHover } from 'cds/styles';

/**
 * 검색어 자동완성 모달
 * @param {object} props
 * @param {string} props.keyword 검색 키워드
 * @param {any[]} props.keywordList 키워드 리스트
 * @param {Function} props.onClose
 * @param {boolean} props.showBackground 배경 존재여부
 */
const AutoComplete = ({ keyword = '', keywordList = [], onClose, showBackground }) => {
  const escapeRegex = value => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pattern = new RegExp(escapeRegex(keyword), 'gi');

  const router = useRouter();

  const [selected, setSelected] = useState(-1);
  const selectedIndex = useRef(selected);
  const target = useRef(null);
  const result = useRef([]);

  const { onClearModal } = useModal();

  const onSearch = text => {
    let value = text;
    value = value.replace(/#/g, '');
    router.push(`/search?keyword=${value}`);
  };

  const onClickItem = text => {
    onSearch(text);
    onClearModal();
    onClose();
  };

  const onExit = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(target.current)) {
      onClearModal();
      onClose();
    }
  };

  const onKeyDown = e => {
    const key = e.key;
    const count = result.current.length;
    if (count === 0) {
      return;
    }

    if (key === 'Enter' && selectedIndex.current > -1) {
      e.stopPropagation();
      onSearch(result.current[selectedIndex.current].key);
      onClearModal();
      onClose();
      return;
    }

    if (key === 'ArrowUp' && selectedIndex.current >= 0) {
      e.preventDefault();
      selectedIndex.current = selectedIndex.current - 1;
      setSelected(selectedIndex.current);
      return;
    }

    if (key === 'ArrowDown' && selectedIndex.current < count - 1) {
      e.preventDefault();
      selectedIndex.current = selectedIndex.current + 1;
      setSelected(selectedIndex.current);
      return;
    }
  };

  /* eslint-disable */
  useEffect(() => {
    document.addEventListener('scroll', onClose);
    document.addEventListener('click', onExit);
    document.addEventListener('keydown', onKeyDown, { capture: true });

    return () => {
      document.removeEventListener('scroll', onClose);
      document.removeEventListener('click', onExit);
      document.removeEventListener('keydown', onKeyDown, { capture: true });
    };
  }, []);
  /* eslint-enable */

  useEffect(() => (result.current = keywordList), [keywordList]);

  /** 키워드 초기화 */
  useEffect(() => {
    selectedIndex.current = -1;
    setSelected(-1);
  }, [keyword]);

  const ClassList = keywordList.map((suggestion, idx) => {
    const splitted = suggestion.key.split(pattern);
    const joined = [
      <span key={-1}>{splitted[0]}</span>,
      splitted.slice(1).map((text, index) => (
        <span key={index}>
          <strong>{keyword}</strong>
          {text}
        </span>
      )),
    ];

    return (
      <Button
        key={suggestion.key}
        onClick={() => onClickItem(suggestion.key)}
        selected={selectedIndex.current === idx}
      >
        <Icon name="ic_search" width={16} height={24} fill={palette.grey50} />
        <p>{joined}</p>
      </Button>
    );
  });

  return (
    <>
      {showBackground && keywordList.length > 0 && (
        <Background onClick={onClose}>
          <Layout>{ClassList}</Layout>
        </Background>
      )}
      {!showBackground && keywordList.length > 0 && <Layout ref={target}>{ClassList}</Layout>}
    </>
  );
};

const Background = styled.div`
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: ${palette.dim.basic};
  z-index: ${zIndex.modal};
`;

const Layout = styled.div`
  touch-action: none;

  ${mediaQuery.large} {
    position: absolute;
    top: calc(100% + 4px);
    left: 50%;
    width: 100%;
    min-height: 40px;
    /* border: 1px solid ${palette.grey020}; */
    border-radius: 8px;
    background-color: ${palette.system.BNB};
    z-index: ${zIndex.modal};
    transform: translateX(-50%);
  }
`;

const Button = styled(TextButton)`
  ${typography.body2_regular};
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 52px;
  max-height: calc(16px * 1.375 * 2 + 32px);
  border: none;
  padding: 16px 24px;
  border-radius: 0;
  background-color: ${({ selected }) => (selected ? palette.grey005 : palette.system.BNB)};
  text-align: left;

  & > svg {
    flex-shrink: 0;
    margin-right: 8px;
  }

  & > p {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: break-spaces;
    color: ${palette.grey50};
    & strong {
      color: ${palette.Secondary.Mint};
      font-weight: 400;
    }
  }

  ${mediaQuery.large} {
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  ${onlyHover} {
    &:hover {
      background-color: ${palette.system.grey};
    }
  }
`;

export default AutoComplete;
